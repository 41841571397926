<template>
  <div class="receita-container">
    
    <!-- -->
      <section id="secao-receita" class="container-fluid">

        <div class="row px-lg-3 py-3" style="background:#F18800;">
            
          <div class="col-sm-10">
              <h2 class="bold" style="color:#FFF;"><router-link to="/receitas"><span> 
     <svg style="color:#FFF;" xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-arrow-left-circle" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
</svg>
                
                </span></router-link> {{nome}} </h2>
               
              <star-rating active-color="#400B41"  v-model="media" :read-only="true" :show-rating="false" :star-size="25" class="d-inline-block" /> <small class="text-white">{{qtd_avaliacoes}} Avaliações</small>
              <p class="d-inline-block ml-5 text-white"><strong>Compartilhe:</strong> <img @click="compartilhar" src="/img/share.png" data-action="share/whatsapp/share" class="img-fluid " style="cursor:pointer;" /></p>
          </div>

              <div class="col-sm-2">
                <p style="display:inline-block;color:#FFF;font-weight:bold;" class="bold ">REDES SOCIAIS: </p><br>
               
                <a target="_blank" href="https://www.facebook.com/tarimbanacozinha" class="d-inline-block mr-4"> <img style="height:40px;cursor:pointer;" src="/img/facebook.png" class="img-fluid " alt="Facebook Tarimba na cozinha" /></a>
                 <a target="_blank" href="https://www.instagram.com/tarimbanacozinha/" class="d-inline-block"><img style="height:40px;cursor:pointer;" src="/img/instagram.png" class="img-fluid " alt="Instagram Tarimba na cozinha" /></a>
                
              </div>

          </div>

<br>
          <div class="row px-lg-3">

            <div class="col-sm-12 col-md-3">

              <div class="row">
                <div class="col-sm-12">
                  <img v-if="receita.RE_TARIMBA == 'Sim'" src="/img/logo_tarimba.jpg" width="250" height="auto"  class="img-fluid img-thumbnail d-block mx-auto" />
                <img v-if="usuario.USU_FOTO != '' && receita.RE_TARIMBA != 'Sim'" :src="usuario.USU_FOTO" width="250" height="auto"  class="img-fluid img-thumbnail d-block mx-auto" />
                
                <img v-if="usuario.USU_FOTO == '' && receita.RE_TARIMBA != 'Sim'" src="/img/no-photo.jpg" width="250" height="auto"  class="img-fluid img-thumbnail d-block mx-auto" />
                <br>
                 <h5 class="txt-receita-roxo text-center"><i>Autor(a):</i></h5>
                    <h4 class="txt-receita-roxo bold text-center">{{ (receita.RE_TARIMBA == 'Sim') ? 'Equipe Tarimba':usuario.USU_NOME}}</h4>
                    <br>
                    <!--<button @click="imprimir" style="background-color:#F18800 !important;color:#FFF !important;" class="btn btn-light bold py-3 px-3">IMPRIMIR RECEITA</button> -->
                </div>

                
              </div>

            </div>

            <div class="col-sm-12 col-md-9">
              <!--   -->
  <section id="tabela-informacao" style="background:#EF953A" class="px-3 px-lg-3 py-3 box-exibicao-receita">
                <div class="row">
                   <div class="col-lg-6">

                     
                    
                      <div class="row">
                          <div class="col-4 box-icone text-center">
                          <p class="txt-receita-white bold text-center" style="font-weight:bold;">CATEGORIA</p>
                          <img :src="'/img/'+icone" class="img-fluid" style="max-width:85px;"/>
                          <h4 class="txt-receita-white bold txt-icones" style="margin-top:5px;">{{categoria}}</h4> 
                          </div>

                        <div class="col-4 box-icone text-center"> 
                          <p class="txt-receita-white bold text-center" style="font-weight:bold;">DIETA</p>
                          <img src="/img/icon_det01.png" class="img-fluid" style=""/>
                          <h4 class="txt-receita-white bold txt-icones" style="margin-top:5px;">
                          {{ (dieta != '') ? dieta:'N/A'}}</h4> 
                          </div>

                        <div class="col-4 box-icone text-center">
                          <p class="txt-receita-white bold text-center" style="font-weight:bold;">RENDIMENTO</p>
                          <img src="/img/icon_det02.png" class="img-fluid" style=""/>
                          <h4 class="txt-receita-white bold txt-icones" style="margin-top:5px;">{{rendimento}} Pessoas</h4> 
                            </div>

                        <div  class="col-4 box-icone text-center">
                          <p class="txt-receita-white bold text-center" style="font-weight:bold;">TEMPO</p>
                          <img src="/img/icon_det03.png" class="img-fluid" style=""/>
                         <h4 class="txt-receita-white bold txt-icones" style="margin-top:5px;">{{tempo}} Min</h4> 
                        </div>

                        <div  class="col-4 box-icone text-center"> 
                          <p class="txt-receita-white bold text-center" style="font-weight:bold;">DIFICULDADE</p>
                          <img src="/img/icon_det04.png" class="img-fluid" style=""/>
                          <h4 class="txt-receita-white bold txt-icones" style="margin-top:5px;">{{dificuldade}}</h4> 
                          </div>

                        <div  class="col-4 box-icone text-center">
                          <p class="txt-receita-white bold text-center" style="font-weight:bold;">CUSTO</p>
                          <img src="/img/icon_det05.png" class="img-fluid" style=""/>
                            <h4 class="txt-receita-white bold txt-icones" style="margin-top:5px;">{{custo}}</h4> 
                            </div>
                      </div>

                  </div>

                   <div class="col-lg-6">
                      <img v-if="foto != null && foto.length > 0" :src="foto"   class="img-fluid img-thumbnail d-block mx-auto" />
                      <img v-else src="/img/no-image.png"   class="img-fluid img-thumbnail d-block mx-auto" />
                       <p v-if="preparo == 'Sim'" class="txt-receita-white"><strong>* Essa receita exige pré-preparo.</strong></p>
                  </div>
                </div>
              </section>



              <!-- -->
              <!--<section id="tabela-informacao" style="background:#EF953A" class="px-3 px-lg-3 py-3 box-exibicao-receita">
                <div class="row">
                   <div class="col-md-12 col-lg-8">
                      <star-rating v-model="media" :read-only="true" :show-rating="false" :star-size="30" />
                      <h2 class="bold txt-receita-white">Categoria</h2>
                       <button @click="adicionarTeste" class="btn btn-light bold float-right d-none d-sm-block " style="color:#F18800;font-size:0.7em;margin-top:-70px;">ADICIONE EM RECEITAS PARA TESTAR</button>
                     <button @click="adicionarTeste" class="btn btn-light bold d-block  d-sm-none" style="color:#F18800;font-size:0.7em;">ADICIONE EM RECEITAS PARA TESTAR</button>

                     <button @click="adicionarCompras" class="btn btn-light bold float-right d-none d-sm-block " style="color:#F18800;font-size:0.7em;margin-top:-30px;">ADICIONE EM LISTA DE COMPRAS</button>
                     <button @click="adicionarCompras" class="btn btn-light bold d-block  d-sm-none" style="color:#F18800;font-size:0.7em;">ADICIONE EM LISTA DE COMPRAS</button>

                     <div class="clearfix"></div>
                      <div class="row px-0">
                        <div class="col-sm-12 px-0">
                            <img :src="'/img/'+icone" class="img-fluid d-inline" style="width:80px;"/> <h5 class="d-inline txt-receita-white">{{categoria}}</h5>
                            <p v-if="preparo == 'Sim'" class="txt-receita-white"><strong>* Essa receita exige pré-preparo.</strong></p>
                        </div>
                       
                      </div>
<hr>
                      <div class="row">
                        <div class="col box-icone text-center"> 
                          <p class="txt-receita-white text-center">Dieta</p>
                          <img src="/img/icon_det01.png" class="img-fluid" style=""/>
                          <h4 class="txt-receita-white bold txt-icones">
                          {{ (dieta != '') ? dieta:'N/A'}}</h4> 
                          </div>

                        <div class="col box-icone text-center">
                          <p class="txt-receita-white text-center">Rendimento</p>
                          <img src="/img/icon_det02.png" class="img-fluid" style=""/>
                          <h4 class="txt-receita-white bold txt-icones">{{rendimento}} Pessoas</h4> 
                            </div>

                        <div  class="col box-icone text-center">
                          <p class="txt-receita-white text-center">Tempo</p>
                          <img src="/img/icon_det03.png" class="img-fluid" style=""/>
                         <h4 class="txt-receita-white bold txt-icones">{{tempo}} Min</h4> 
                        </div>

                        <div  class="col box-icone text-center"> 
                          <p class="txt-receita-white text-center">Dificuldade</p>
                          <img src="/img/icon_det04.png" class="img-fluid" style=""/>
                          <h4 class="txt-receita-white bold txt-icones">{{dificuldade}}</h4> 
                          </div>

                        <div  class="col box-icone text-center">
                          <p class="txt-receita-white text-center">Custo</p>
                          <img src="/img/icon_det05.png" class="img-fluid" style=""/>
                            <h4 class="txt-receita-white bold txt-icones">{{custo}}</h4> 
                            </div>
                      </div>

                  </div>

                   <div class="col-md-12 col-lg-4">
                      <img v-if="foto != null && foto.length > 0" :src="foto"   class="img-fluid img-thumbnail mx-auto" />
                      <img v-else src="/img/no-image.png"   class="img-fluid img-thumbnail mx-auto" />
                  </div>
                </div>
              </section> -->
            
            </div>

          </div>



<br>


                <hr class="">  
<br>

<section class="row px-lg-3">
    <div class="col-sm-6">
          <Anuncio :id="5" padrao="/img/publicidade1.jpg" class="d-none d-sm-block" :mobile="false" />
           <Anuncio :id="5" padrao="/img/publicidade1.jpg" class="d-sm-none" style="margin-bottom:10px;" :mobile="true" />
    </div>
    <div class="col-sm-6">
          <Anuncio :id="6" padrao="/img/publicidade2.jpg" class="d-none d-sm-block" :mobile="false" />
           <Anuncio :id="6" padrao="/img/publicidade2.jpg" class="d-sm-none" :mobile="true" />
    </div>
</section>

<br>
 <hr class="">  
 <br>


<div class="py-3 px-3" style="">
  <div class="row">
 
    <div class="col-sm-12">
     <h5 class="bold " style="margin-top:7px;">
                
                Separe todos os utensílios e ingredientes necessários antes de iniciar a preparação.
              </h5>
  </div>
  </div>
             
            </div>


 <br> <br>

        <section id="tabela-equipamento" class="row px-lg-3">
          <div class="col-sm-6 margin-mobile-receita">
           <img src="/img/icon_receitas_utensilios.png" class="img-fluid"/>
<h1 class="bold d-inline " style="margin-left:20px;">Utensílios</h1><br><br>

<h4 class="txt-receita-roxo" v-for="(item,index) in utensilios" :key="'utensilio'+index">- {{item.nome}}</h4>


          </div>


           <div class="col-sm-6">

  <img src="/img/icon_receitas_equipamentos.png" class="img-fluid"/>
<h1 class="bold d-inline " style="margin-left:20px;">Equipamentos</h1><br><br>

<h4 class="txt-receita-roxo" v-for="(item,index) in equipamentos" :key="'equipamento'+index">- {{item.nome}}</h4>
<br>
           </div>

          
<br>
           
        </section>
         <!--<Anuncio :id="5" padrao="/img/publicidade1.jpg" class="d-none d-sm-block" :mobile="false" />
           <Anuncio :id="5" padrao="/img/publicidade1.jpg" class="d-sm-none" :mobile="true" /> -->

<br><br>
<hr>
<br><br>


       <section class="row px-lg-3">
          <div class="col-sm-12">
  <img src="/img/celular.jpeg" class="img-fluid d-sm-none d-block mx-auto"/>
  <small class="text-center d-sm-none d-block mx-auto">Vire seu aparelho para poder melhor visualizar a tabela de ingredientes.</small>
  <br>  <br>
 <img src="/img/icon_receitas_ingredientes.png" class="img-fluid"/>
<h2 class="bold d-inline " style="margin-left:20px;">Ingredientes</h2>
          </div>

         
       </section>

       <section class="row px-lg-3">
           <div class="col-sm-12"><br>
             



            <div class="py-3 px-3 margin-mobile-receita" style="">
              <h5 class="bold " style="margin-top:7px;"> Marque os ingredientes que faltam na coluna da esquerda <button @click="addListaCompras" class="btn btn-light bold" style="">CLIQUE AQUI!</button> e marque-os para a Lista de Compras.</h5>
            </div>
         


<!-- table -->
<div id="tabela-ingredientes" class="table-responsive">
<table class="table">
  <thead class="thead-light">
     <tr>
      <th scope="col"></th>
      <th scope="col"  colspan="2">Medidas Domésticas</th>
      <th scope="col" colspan="2">Medidas Decimais</th>
     
      <th scope="col"></th>
       <th scope="col"></th>
       
       
    </tr>
    <tr>
      <th width="20" scope="col"></th>
      <th  width="30" align="right" scope="col">Quant</th>
      <th width="170" scope="col">UNID</th>
      <th width="30" align="right" scope="col">Quant</th>
      <th width="170" scope="col">UNID</th>
      <th width="450" scope="col">Ingrediente</th>
       <th scope="col">Observações</th>
       
       
    </tr>
  </thead>
  <tbody>
    <tr v-for="(item,index) in ingredientes" :key="'ingredienteReceita'+index">
      <td><input class="selectCompras" type="checkbox" :value="item" v-model="ingredientesEscolhidos" /></td>
      <td align="right" style="background-color:#FFB95A !important;font-weight:bold;">{{item.qtd.replace('.',',').replace(',0','')}}</td>
      <td style="background-color:#FFB95A !important;font-weight:bold;">{{item.medida}}</td>
      <td  align="right" style="background-color:#FFE6C9 !important;">{{item.volume.replace('.',',').replace(',0','')}}</td>
      <td style="background-color:#FFE6C9 !important;">{{item.decimal}}</td>
      <td style="background-color:#FFB95A  !important;font-weight:bold;">{{item.nome}}</td>
      <td style="background-color:#FFE6C9  !important;">{{item.obs}}</td>
     
     
     
    </tr> 
   
  </tbody>
</table>
 <p>UP: Ingredientes Ultra Processados. <strong>EVITE O CONSUMO DE ALIMENTOS ULTRAPROCESSADOS.</strong></p>
</div>
<!--<Anuncio :id="6" padrao="/img/publicidade2.jpg" class="d-none d-sm-block" :mobile="false" />
           <Anuncio :id="6" padrao="/img/publicidade2.jpg" class="d-sm-none" :mobile="true" /> -->
<br>
<!-- -->





           </div>
       </section>

<br><br>
<hr>
<br><br>
<section class="row px-lg-3 section-preparo">
          <div class="col-sm-12">
<svg width="4em" height="4em" viewBox="0 0 16 16" class="bi bi-list-ol" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5z"/>
  <path d="M1.713 11.865v-.474H2c.217 0 .363-.137.363-.317 0-.185-.158-.31-.361-.31-.223 0-.367.152-.373.31h-.59c.016-.467.373-.787.986-.787.588-.002.954.291.957.703a.595.595 0 0 1-.492.594v.033a.615.615 0 0 1 .569.631c.003.533-.502.8-1.051.8-.656 0-1-.37-1.008-.794h.582c.008.178.186.306.422.309.254 0 .424-.145.422-.35-.002-.195-.155-.348-.414-.348h-.3zm-.004-4.699h-.604v-.035c0-.408.295-.844.958-.844.583 0 .96.326.96.756 0 .389-.257.617-.476.848l-.537.572v.03h1.054V9H1.143v-.395l.957-.99c.138-.142.293-.304.293-.508 0-.18-.147-.32-.342-.32a.33.33 0 0 0-.342.338v.041zM2.564 5h-.635V2.924h-.031l-.598.42v-.567l.629-.443h.635V5z"/>
</svg>
<h2 class="bold d-inline " style="margin-left:20px;">Modo de preparo</h2><br><br>

<h4 class="txt-receita-roxo bold">Passo 1:</h4>
<pre class="txt-receita-modo">{{passo1}}</pre>
<br>
<h4 class="txt-receita-roxo bold">Passo 2:</h4>
<pre class="txt-receita-modo">{{passo2}}</pre>
<br>
<h4 class="txt-receita-roxo bold">Passo 3:</h4>
<pre class="txt-receita-modo">{{passo3}}</pre>
<br>
<h4 class="txt-receita-roxo bold">Passo 4:</h4>
<pre class="txt-receita-modo">{{passo4}}</pre>
<br>
<h4 class="txt-receita-roxo bold">Passo 5:</h4>
<pre class="txt-receita-modo">{{passo5}}</pre>
<br>
<h4 class="txt-receita-roxo bold">Passo 6:</h4>
<pre class="txt-receita-modo">{{passo6}}</pre>

<br>

<div class="py-5 px-2 px-sm-4" style="background:#400B41;">
              <h3 class="bold txt-receita-laranja" style="">
                Dicas que podem melhorar sua receita
              </h3>
              <pre class="txt-receita-white">{{sugestao.trim()}}</pre>

              <h3 class="bold txt-receita-laranja" style="">
                Sugestões de acompanhamento
              </h3>
              <pre class="txt-receita-white">{{acompanhamento.trim()}}</pre>

              <h3 class="bold txt-receita-laranja" style="">
                Pontos de atenção
              </h3>
              <pre class="txt-receita-white">{{pontos.trim()}}</pre>

              <h3 class="bold txt-receita-laranja" style="">
                Terminologia técnica
              </h3>
              <pre class="txt-receita-white">{{termos.trim()}}</pre>

            </div>





          </div>
          
       </section>
<br><br>
<hr>
       <section class="row px-lg-3">
    <div class="col-sm-4 my-2">
       <img @click="imprimir" style="display:inline-block;cursor:pointer;margin:0 10px;" src="/img/impressora.png" class="img-fluid" alt="Imprimir receita Tarimba" />
        <button @click="imprimir" style="background-color:#400B41 !important;color:#FFF !important;display:inline-block;" class="btn btn-light bold">IMPRIMIR RECEITA</button>  
    </div>
     <div class="col-sm-4 my-2">
       <img @click="adicionarCompras" style="display:inline-block;cursor:pointer;margin:0 10px;" src="/img/carrinho.png" class="img-fluid" alt="Adicionar lista compras Tarimba" />
        <button @click="adicionarCompras" style="background-color:#400B41 !important;color:#FFF !important;display:inline-block;" class="btn btn-light bold">ADICIONAR EM<br>LISTA DE COMPRAS</button>  
    </div>
    <div class="col-sm-4 my-2">
       <img @click="adicionarTeste" style="display:inline-block;cursor:pointer;margin:0 10px;" src="/img/avental.png" class="img-fluid" alt="Adicionar teste Tarimba" />
        <button @click="adicionarTeste" style="background-color:#400B41 !important;color:#FFF !important;display:inline-block;" class="btn btn-light bold">ADICIONAR EM<br>RECEITA PARA TESTAR</button>  
    </div>
    
</section>

<br><br>
<hr>
<br>

<section class="row px-lg-3">
<div class="col-sm-12">
  <h2 class="bold" style="">Faça sua classificação e deixe seu comentário</h2>
  <h3 class="" style="">Ao classificar ou comentar uma receita ela vai automaticamente para o seu livro de receitas.</h3><br>
  <p class="d-inline">Deixe sua avaliação:</p>
  <star-rating v-model="nota" @rating-selected="avaliar" />
  <br>

  <textarea v-model="comentario" class="form-control" maxlength="500" spellcheck="true" placeholder="Deixe seu comentário aqui..."></textarea><br>
  <button @click="comentar" class="btn btn-light bold float-right" style="color:#FFF; background-color: #400041 !important;">ENVIAR COMENTÁRIO</button>
  <div class="clearfix"></div>
<hr>
<h2 class="bold" style="">Comentários</h2>
<br>
<Comentario :url="'gerenciaComentarios/getComentarios/'+ this.$route.params.id" :id="parseInt(this.$route.params.id)"/>


</div>
</section>

<br><br>
<hr>
                  
      </section>
<!-- -->


 <div class="modal" id="modal-receita" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Informações importantes!</h5>
        <button  type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>{{texto}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
        
      </div>
    </div>
  </div>
</div>

 <div class="modal" id="modal-lista-compras" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Informações importantes!</h5>
        <button  type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>{{texto}}</p>
      </div>
      <div class="modal-footer">
        
        <router-link to="/lista-de-compras"  class="btn btn-secondary" data-dismiss="modal">Ir para lista de compras</router-link>
        
      </div>
    </div>
  </div>
</div>






  </div>
</template>

<style>
.txt-receita-modo{color:#000;background-color:#E9ECEF;padding:5px;border-radius:5px;}
.txt-receita-roxo{color:#400B41;}
.txt-receita-laranja{color:#F18800;}
.txt-receita-white{color:#FFF;}
.box-icone{margin-bottom:25px;}
.box-icone h4{font-size:1.2em;}
.section-preparo pre{font-size:1.1em;font-family: plume;}
.box-exibicao-receita{
  -webkit-box-shadow: 4px 3px 15px 0px rgba(0,0,0,0.93); 
box-shadow: 4px 3px 15px 0px rgba(0,0,0,0.93);
}


@media all and (max-width:768px){
  .box-exibicao-receita{margin-top:15px;}
  .margin-mobile-receita{margin-bottom:10px;margin-top:10px;}
}
</style>

<script>
import servidor from '@/servidor'
import StarRating from 'vue-star-rating'
import Comentario from '../components/Comentario.vue'
import Anuncio from '@/components/Anuncio'

export default {
  name: 'VisualizarReceita',
  components: {StarRating,Comentario,Anuncio},
  data(){
    return {
      ingredientesEscolhidos:[],
      icone:'',
      id: null,
      categoria:'',
      custo:'',
      dificuldade:'',
      rendimento:1,
      tempo:1,
      dieta:'',
      preparo:'Não',
      passo1:'',
      passo2:'',
      passo3:'',
      passo4:'',
      passo5:'',
      passo6:'',
      sugestao:'',
      acompanhamento:'',
      termos:'',
      pontos:"",
      foto:'',
      texto:'',
      nome:'',
      utensilios:[],
      equipamentos:[],
      ingredientes:[],
      usuario:{},
      receita:{},
      nota:0,
      media:0,
      qtd_avaliacoes:0,
      comentario:''
    }
  },
  computed:{
    getUsuario(){
      return this.$store.state.usuario.id;
    }
  },
  methods:{
    compartilhar(){
      
      //let conteudo = encodeURIComponent(this.nome + '  Categoria - ' + this.categoria +'  Rendimento - ' + this.rendimento + ' pessoas - Dificuldade - ' + this.dificuldade +' - Tempo: ' + this.tempo + 'Min : ' + window.location.href);
      let conteudo = encodeURIComponent(this.nome + ' : ' + window.location.href + ' Já conhece essa receita da Tarimba na Cozinha?');
       window.open("https://api.whatsapp.com/send?text=" + conteudo);  
        
    },
 imprimir(){
       
        var style = "<style  type='text/css'>";
        style = style + "table {width: 100%;font: 20px Calibri;border:1px solid #000;}";
        style = style + "table, th {border: solid 1px #DDD; border-collapse: collapse;font: 17px Calibri;font-weight:normal;";
        style = style + "padding: 2px 3px;text-align: center;}";
        style = style + "</style>";
        style += '<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css" integrity="sha384-B0vP5xmATw1+K9KRQjQERJvTumQW0nPEzvF6L/Z6nronJ3oUOFUFpCjEUQouq2+l" crossorigin="anonymous">';
        // CRIA UM OBJETO WINDOW
        var win = window.open('', '', 'height=700,width=700');
        win.document.write('<html><head>');
        win.document.write('<title>'+this.nome+'</title>');   // <title> CABEÇALHO DO PDF.
        win.document.write(style);                                     // INCLUI UM ESTILO NA TAB HEAD
        win.document.write('</head>');
        win.document.write('<body>');
        win.document.write('<h1 style="display:block;margin:0 auto;">'+this.nome+'</h1><br>');

      
        win.document.write('<p><strong>Rendimento:</strong> '+this.rendimento+' Pessoas</p>');
        win.document.write('<p><strong>Tempo:</strong> '+this.tempo+' Min</p>');

        let tabelaEquipamento = window.jQuery('#tabela-equipamento').html();
         win.document.write(tabelaEquipamento);
        let tabelaIngredientes = window.jQuery('#tabela-ingredientes').html();
         win.document.write(tabelaIngredientes);
          win.document.write('<br> <h3>Modo de preparo</h3>');
           win.document.write('<br> <p><strong>Passo 1:</strong> '+this.receita.RE_PASSO1+'</p>');
           win.document.write('<p><strong>Passo 2:</strong> '+this.receita.RE_PASSO2+'</p>');
           win.document.write('<p><strong>Passo 3:</strong> '+this.receita.RE_PASSO3+'</p>');
           win.document.write('<p><strong>Passo 4:</strong> '+this.receita.RE_PASSO4+'</p>');
           win.document.write('<p><strong>Passo 5:</strong> '+this.receita.RE_PASSO5+'</p>');
           win.document.write('<p><strong>Passo 6:</strong> '+this.receita.RE_PASSO6+'</p><br><br>');
           win.document.write('<p><strong>Dicas:</strong> '+this.acompanhamento+'</p><br><br>');
            win.document.write('<p><strong>Sugestão de acompanhamento:</strong> '+this.sugestao+'</p><br><br>');
            win.document.write('<p><strong>Pontos de atenção:</strong> '+this.pontos+'</p><br><br>');
            win.document.write('<p><strong>Termos técnicos:</strong> '+this.termos+'</p>');
       /* win.document.write('<table id="tabela" class="table">');
        win.document.write('<tr><th scope="col">Quant</th><th scope="col">UNID</th><th scope="col">Ingrediente</th></tr>');
        this.lista.forEach(element => {
          win.document.write('<tr><th scope="col">'+element.volume+'</th><th scope="col">'+element.decimal+'</th><th scope="col">'+element.nome+'</th></tr>');
        });
        win.document.write('</table>');   */                         // O CONTEUDO DA TABELA DENTRO DA TAG BODY
        win.document.write('</body></html>');
        win.document.close(); 	                                         // FECHA A JANELA
        win.print();               
    },

    adicionarCompras(){
      window.jQuery('.selectCompras').trigger('click');
      this.addListaCompras();
    },
    addListaCompras(){
      if(this.ingredientesEscolhidos.length > 0){
      let receitaCompras = {
        ingredientes:this.ingredientesEscolhidos,
        nome:this.nome,
        categoria:this.categoria,
        id:this.id,
        dieta:this.dieta,
        rendimento:this.rendimento,
        dificuldade:this.dificuldade,
        custo:this.custo,
        tempo:this.tempo
      }
      this.$store.commit('alterarCompras',receitaCompras);
      this.abrirModalLista("Receita adicionada com sucesso! Você pode visualizar os ingredientes selecionados a qualquer momento no menu Lista de Compras.");
      }
    },
    comentar(){
      if(this.comentario != ''){
         let dados = new FormData();
         dados.append('usuario',this.getUsuario);
         dados.append('comentario',this.comentario);
         dados.append('tipo','Receita');
         dados.append('resposta','Não');
         dados.append('conta',this.$store.state.usuario.permissao);
         fetch(servidor+'gerenciaComentarios/comentar/'+ this.id,{method:"POST", body:dados})
          .then((response) => response.json())
          .then((data) => {
            this.comentario = "";
            console.log(data);
            this.abrirModal("Obrigado por enviar seu comentário! Ele será análisado pela nossa equipe e será publicado em breve.");
          });
      }
    },
    avaliar(){
        fetch(servidor+'buscaTarimba/avaliar/'+ this.id + "/" + this.getUsuario + "/" + this.nota)
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
            
            this.abrirModal("Obrigado por avaliar essa receita! A partir de agora ela ficará visível no seu 'Livro de receitas'.");
          });
    },
    adicionarTeste(){
       fetch(servidor+'buscaTarimba/adicionarReceitaTeste/'+ this.id + "/" + this.getUsuario)
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
            
            this.abrirModal("Receita adicionada à sua lista com sucesso! A partir de agora ela ficará visível no menu 'Receitas para testar'.");
          });
    },
    abrirModal(msg){
    this.texto = msg;
    window.jQuery('#modal-receita').modal('show');
   },
   abrirModalLista(msg){
    this.texto = msg;
    window.jQuery('#modal-lista-compras').modal('show');
   },
   buscarReceita(){
     let dados = new FormData();
     dados.append('usuario',this.getUsuario);
      fetch(servidor+'gerenciaReceita/getReceita/'+this.id,{method:"POST", body:dados})
          .then((response) => response.json())
          .then((data) => {
            console.log(data)
            if(data.erro != null){
                this.abrirModal(data.erro);
            }else{
              this.receita = data.receita;
               this.nome = data.receita.RE_NOME;
               this.categoria = data.receita.RE_CATEGORIA;
               this.custo = data.receita.RE_CUSTO;
               this.dificuldade = data.receita.RE_DIFICULDADE;
               this.rendimento = data.receita.RE_RENDIMENTO;
               this.tempo = data.receita.RE_TEMPO;
               this.preparo = data.receita.RE_PREPARO;
               this.dieta = data.receita.RE_DIETA;
               this.passo1 = data.receita.RE_PASSO1;
               this.passo2 = data.receita.RE_PASSO2;
               this.passo3 = data.receita.RE_PASSO3;
               this.passo4 = data.receita.RE_PASSO4;
               this.passo5 = data.receita.RE_PASSO5;
               this.passo6 = data.receita.RE_PASSO6;
               this.sugestao= data.receita.RE_SUGESTAO;
               this.termos = data.receita.RE_TERMOS;
               this.pontos = data.receita.RE_PONTOS;
               this.acompanhamento = data.receita.RE_ACOMPANHAMENTO;
               this.foto = data.receita.RE_FOTO;
               this.usuario = data.usuario;
               this.nota = parseInt(data.avaliacao);
               this.media = (data.receita.RE_NOTA != null) ? parseInt(data.receita.RE_NOTA):0;
               this.qtd_avaliacoes = parseInt(data.qtd_avaliacao);
               this.setarIcone();

               data.utensilios.forEach((utensilio) => this.utensilios.push({nome:utensilio.UR_NOME,novo:utensilio.UR_NOVO}));
               data.equipamentos.forEach((equipamento) => this.equipamentos.push({nome:equipamento.ER_NOME,novo:equipamento.ER_NOVO}));
               data.ingredientes.forEach((ingrediente) => this.ingredientes.push(
                 {nome:ingrediente.IR_NOME,novo: ingrediente.IR_NOVO,qtd:ingrediente.IR_QUANTIDADE.toString(),medida:ingrediente.IR_DOMESTICA,obs:ingrediente.IR_OBSERVACAO,
                 posicao:ingrediente.IR_POSICAO,volume:ingrediente.IR_VOLUME,decimal:ingrediente.IR_DECIMAL}
               ));
            }
        });
    },
    setarIcone(){
      switch(this.categoria){
       
         case 'Entradas':
          this.icone = 'icon_categoria1.png';
        break;
         case 'Sopas, Cremes e Caldinhos':
          this.icone = 'icon_categoria2.png';
        break;
         case 'Aperitivos, Comidinhas e Lanches':
          this.icone = 'icon_categoria3.png';
        break;
        case 'Pratos Principais':
          this.icone = 'icon_categoria4.png';
        break;
         case 'Acompanhamentos':
          this.icone = 'icon_categoria5.png';
        break;
           case 'Sobremesas':
          this.icone = 'icon_categoria6.png';
        break;
         
           case 'Bolos, Doces e Pães':
          this.icone = 'icon_categoria7.png';
        break;
         case 'Molhos e Caldos Clássicos':
          this.icone = 'icon_categoria8.png';
        break;
      }
    }
  },
  mounted(){
    this.$scrollTo("#secao-receita");
    if(this.$route.params.id != null){
      this.id = this.$route.params.id;
      this.buscarReceita();
      
    }
  }
}
</script>
