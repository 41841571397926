<template>
  <div class="container-comentario">
  <div class="row">
    <div class="col-sm-12">
            <!-- -->
  <div class="media" v-for="(item,index) in resultados" :key="'comentario'+index">
    <img v-if="item.foto != ''" :src="item.foto" width="110"  class="mr-3 img-thumbnail" alt="Avatar Tarimba">
  <img v-else src="/img/no-photo.jpg" width="110"  class="mr-3" alt="Avatar Tarimba">
  <div class="media-body">
    <h5 class="mt-0" style="color:#400B41;"><i>{{item.nome}}</i></h5>
    <small class="d-block" style="color:#400B41;" v-if="item.tarimba == 'Sim'"><i>Equipe Tarimba na cozinha</i></small>
     <star-rating v-if="blog == false" :rating="item.avaliacao | converter" :read-only="true" :show-rating="false" :star-size="20" />
    {{item.comentario}}<br>
     <small class="" style="color:#c9c5c5;">{{item.data}}</small><br>
    <small @click="abrirCampo(index)" style="color:#400B41;cursor:pointer;"><i>Responder</i></small>
<div :ref="'campoResposta'+index" style="display:none;">
    <textarea v-model="resposta" class="form-control" maxlength="500" spellcheck="true" placeholder="Deixe sua resposta aqui..."></textarea><br>
  <button @click="responder(item.id)" class="btn btn-light bold float-right" style="color:#FFF; background-color: #400041 !important;">ENVIAR</button>
  <div class="clearfix"></div>
</div>
    <div class="media mt-3" v-for="(item2,index2) in item.respostas" :key="'comentario'+index2">
      <a class="mr-3" href="#">
        <img v-if="item2.foto != ''" :src="item2.foto" width="50"  class="mr-3 img-thumbnail" alt="Avatar Tarimba">
        <img v-else src="/img/no-photo.jpg" width="50"  class="mr-3" alt="Avatar Tarimba">
      </a>
      <div class="media-body">
        <h5 class="mt-0" style="color:#400B41;">{{item2.nome}}</h5>
         <small class="d-block" style="color:#400B41;" v-if="item2.tarimba == 'Sim'"><i>Equipe Tarimba na cozinha</i></small>
        <star-rating v-if="blog == false" :rating="item2.avaliacao | converter" :read-only="true" :show-rating="false" :star-size="20" />
        {{item2.comentario}}<br>
        <small style="color:#c9c5c5;">{{item2.data}}</small><br>
      </div>
    </div><br><hr>
  </div>
  
</div>




<div v-if="showLoading" class="d-flex justify-content-center">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>

  <p v-if="semResultados" class="text-center bold">Sem Resultados...</p>
 <div class="d-flex justify-content-center">
 
        <button v-if="semResultados == false" @click="paginar" style="width:300px;" class="btn btn-light btn-block btn-search-ingredients bold">CARREGAR MAIS COMENTÁRIOS</button>
         </div>
        
        <br><br>

            <!-- -->


             <div class="modal" id="modal-info" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Informações importantes!</h5>
        <button  type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>{{texto}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
        
      </div>
    </div>
  </div>
</div>


    </div>
  </div>
  </div>
</template>

<script>
import servidor from '@/servidor'
import StarRating from 'vue-star-rating'

export default {
  name: 'Comentario',
  components: { StarRating},
  props: {
    url:String,
    id:Number,
    blog:Boolean
  },
  data(){
    return {
      limiteResultados:10,
      resultados: [],
      resposta:'',
      texto:'',
      semResultados:false,
      showLoading:false
    }
  },
  filters: {
  converter: function (value) {
    return parseInt(value);
    }
  },
  computed:{
    getUsuario(){
      return this.$store.state.usuario.id;
    }
  },
  methods:{
    paginar(){
       this.showLoading = true;

       let filtro = new FormData();
        filtro.append('paginacao',this.resultados.length);

        fetch(servidor+this.url,{method:"POST", body:filtro})
          .then((response) => response.json())
          .then((data) => {
             if(data == null || data.length == 0){ this.semResultados = true;}
             data.forEach(element => {
                this.resultados.push(element);
            });
            this.showLoading = false;
          }); 
    },
    abrirModal(msg){
    this.texto = msg;
    window.jQuery('#modal-info').modal('show');
   },
    abrirCampo(index){
      if(this.$refs['campoResposta'+index][0].style.display == 'block'){
        this.$refs['campoResposta'+index][0].style.display = 'none';
      }else{
        this.$refs['campoResposta'+index][0].style.display = 'block';
      }
        
    },
    responder(index){
      if(this.$store.state.usuario == null){ this.abrirModal("É nescessário estar logado para fazer um comentantário.");return;}
     if(this.comentario != ''){
         let dados = new FormData();
         dados.append('usuario',this.getUsuario);
         dados.append('comentario',this.resposta);
         if(this.blog == true){
          dados.append('tipo','Blog');
         }else{
          dados.append('tipo','Receita');
         }
         
         dados.append('resposta','Sim');
         dados.append('destino',index);
         dados.append('conta',this.$store.state.usuario.permissao);
         fetch(servidor+'gerenciaComentarios/comentar/'+ this.id,{method:"POST", body:dados})
          .then((response) => response.json())
          .then((data) => {
            this.resposta = "";
            console.log(data);
            this.abrirModal("Obrigado por enviar seu comentário! Ele será análisado pela nossa equipe e será publicado em breve.");
          });
      }
    },
     buscar() {
        let filtro = new FormData();
        filtro.append('paginacao',0);
        fetch(servidor+this.url,{method:"POST", body:filtro})
          .then((response) => response.json())
          .then((data) => {
            this.resultados = data;
          }); 
    }

  },
  mounted(){
     this.buscar();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
